import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "./Icon";
import { StaticFluidImage } from "./StaticImage";

const UiState = {
  init: 0,
  gifLoading: 1,
  animate: 2
};

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class LogoLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: UiState.init
    };
  }

  async componentDidMount() {
    // await delay(1000);
    this.setState({
      uiState: UiState.gifLoading
    });
  }

  render() {
    let { uiState } = this.state;
    return (
      <Wrapper>
        <div className="container">
          {uiState === UiState.init && (
            <StaticFluidImage
              path={`khs-logo-static.jpg`}
              style={{
                maxWidth: "1200px",
                objectFit: "contain",
                minHeight: 324,
                maxHeight: 675
              }}
            />
          )}

          {uiState === UiState.gifLoading && (
            <>
              <StaticFluidImage
                path={`khs-logo-static.jpg`}
                style={{
                  maxWidth: "1200px",
                  objectFit: "contain",
                  minHeight: 324,
                  maxHeight: 675
                }}
              />
              <img
                src={`/images/khs-logo-animation.gif`}
                onLoad={this._onGifLoaded}
                style={{ display: "none" }}
              />
            </>
          )}

          {uiState === UiState.animate && (
            <>
              <img
                src={`/images/khs-logo-animation.gif`}
                style={{
                  maxWidth: "1200px",
                  objectFit: "contain",
                  minHeight: 324,
                  maxHeight: 675
                }}
              />
            </>
          )}

          <div class="dots">
            <div />
            <div />
            <div />
          </div>
        </div>
      </Wrapper>
    );
  }

  _onGifLoaded = async () => {
    this.setState({ uiState: UiState.animate });
    await delay(5000);
    this.props.onClose();
  };
}

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
  overflow: hidden;

  & > .close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  & > .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    width: 100%;
    height: 100%;

    .dots {
      width: 3.5em;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      position: absolute;
      bottom: 160px;

      @media screen and (max-width: 375px) {
        bottom: 120px;
      }
    }

    .dots div {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: white;
      animation: fade 0.8s ease-in-out alternate infinite;
    }

    .dots div:nth-of-type(1) {
      animation-delay: -0.4s;
    }

    .dots div:nth-of-type(2) {
      animation-delay: -0.2s;
    }

    @keyframes fade {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
`;

export default LogoLoading;
