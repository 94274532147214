import React, { Component } from "react";
import "../index.css";
import styled from "styled-components";
import MuiWrapper from "../MuiWrapper";
import AppConfig from "../Config";
import NavbarFactory from "../Components/Navbar";
import FooterFactory from "../Components/Footer";
import LogoLoading from "../Components/LogoLoading";
import Settings from "../Domain/constants";
import { Helmet } from "react-helmet";

const Navbar = NavbarFactory(AppConfig.navbar);
const Footer = FooterFactory(AppConfig.footer);

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLogoLoading: true
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this._onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }

  render() {
    let {
      location: { pathname }
    } = this.props;
    let { openLogoLoading } = this.state;

    let passBreakpoint = !!this.passBreakpoint;
    pathname = this._normalizePath(pathname);

    let withPaddingTop = AppConfig.navbar.coverContent ? false : false;
    if (pathname.slice(-4) === "/me/") {
      withPaddingTop = true;
    } else if (pathname.slice(-6) === "/cart/") {
      withPaddingTop = true;
    }

    return (
      <MuiWrapper>
        <Layout.Wrapper withPaddingTop={withPaddingTop}>
          <Helmet>
            <title>Mapex 30th Anniversary</title>
            <meta property="og:image:type" content="image/jpg" />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="375" />
            <meta
              property="og:image"
              content={`${Settings.webUrl}/images/fb-share-img.jpg`}
            />
            <meta name="description" content="" />
            <link rel="shortcut icon" href="/khs-favicon.png" />
          </Helmet>

          {openLogoLoading ? (
            <LogoLoading
              onClose={() => this.setState({ openLogoLoading: false })}
            />
          ) : (
            <>
              <Navbar passBreakpoint={passBreakpoint || withPaddingTop} />
              <Layout.Content>{this.props.children}</Layout.Content>
              <Footer />
            </>
          )}
        </Layout.Wrapper>
      </MuiWrapper>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === "//") {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== "/") {
      path += "/";
    }

    return path;
  };

  _onScroll = e => {
    const breakpoint = 600;
    let scrollTop = document.querySelector("html").scrollTop;

    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };
}

Layout.Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: black;
  padding-top: ${props =>
    props.withPaddingTop ? AppConfig.navbar.height : 0}px;
  display: flex;
  flex-direction: column;
`;

Layout.Content = styled.div`
  flex-grow: 1;
  align-self: stretch;
`;

export default Layout;
