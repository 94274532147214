import React from "react";
import styled from "styled-components";
import { animateScroll as aniScroll } from "react-scroll";
import Link from "../Link";
import * as Widget from "../Widget";
import * as Icon from "../Icon";
import "../../global-styles";

export default function NavbarFactory({
  coverContent = true,
  background = "rgba(0,0,0,0)",
  altBackground = "#0079ff",
  textColor = "white",
  textShadow = "2px 2px #ccc",
  height = 60
} = {}) {
  const ScreenSizeBoundry = 800;
  const navItems = [
    { children: "30th ANNIVERSARY", id: "anniversary" },
    { children: "ARTIST GREETING VIDEO", id: "video" },
    { children: "LIMITED EDITION PRODUCTS", id: "product" },
    { children: "QUIZ", id: "quiz" }
  ];

  class Navbar extends React.Component {
    state = {
      openMenu: false
    };

    render() {
      let { passBreakpoint } = this.props;
      let { openLogoAnimation } = this.state;

      return (
        <Style.Wrapper passBreakpoint={passBreakpoint}>
          <Style.Content>{this._navDrawer()}</Style.Content>
        </Style.Wrapper>
      );
    }

    _navDrawer() {
      let { passBreakpoint } = this.props;
      const toggleMenuState = () =>
        this.setState({ openMenu: !this.state.openMenu });

      const openLogoAnimation = () =>
        this.setState({ openLogoAnimation: true });

      return (
        <Style.NavDrawerWrapper
          Breakpoint={ScreenSizeBoundry}
          HeightBreakpoint={passBreakpoint}
        >
          <div className="icon-button" onClick={toggleMenuState}>
            <Icon.Menu color="white" />
          </div>

          <div className="brand-logo" onClick={openLogoAnimation} />

          <Style.NavDrawerContainer
            open={this.state.openMenu}
            onClick={toggleMenuState}
          >
            <div className="container">
              {[
                { children: <Icon.Home color="white" />, url: "/" },
                ...navItems
              ].map(({ children, id, url }, idx) => {
                if (idx === 0) {
                  return (
                    <Link to={url} key={idx} className="nav-item">
                      {children}
                    </Link>
                  );
                }
                return (
                  <div
                    key={idx}
                    className="nav-item"
                    onClick={() => this._scrollSection(id)}
                  >
                    {children}
                  </div>
                );
              })}
              <button onClick={() => this.setState({ openMenu: false })}>
                CLOSE
              </button>
            </div>
          </Style.NavDrawerContainer>
        </Style.NavDrawerWrapper>
      );
    }

    _scrollSection(id) {
      const element = document.getElementById(id);
      const targetHeight = element.offsetTop;

      this.setState({ openMenu: false });
      aniScroll.scrollTo(targetHeight - 20, { duration: 500 });
    }
  }

  const Style = {
    Wrapper: styled.div`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      padding: 0px 20px;
      z-index: 10;
      background: ${background};
      transition: background 200ms ease-in-out;
    `,

    Content: styled.div`
      margin: 0 auto;
      max-width: 1270px;
      width: 100%;
      height: 140px;
      display: flex;
      align-items: center;

      @media screen and (max-width: 1000px) {
        height: 90px;
      }
    `,

    NavDrawerWrapper: styled.div`
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      & > .icon-button {
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        margin-top: 100px;
        width: 35px;
        height: 35px;
        background: #4f4f4f;
      }

      & > .brand-logo {
        cursor: pointer;
        margin-top: 100px;
        max-width: 100px;
        width: 100%;
        height: 100px;
        background-image: url("/images/khs-logo02.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        ${props => props.HeightBreakpoint && "display: none"};
      }

      @media screen and (max-width: 1000px) {
        align-items: center;
        & > .icon-button {
          margin-top: 23px;
        }
        & > .brand-logo {
          margin-top: 10px;
          width: 80px;
          height: 80px;
        }
      }
    `,

    NavDrawerContainer: styled.div`
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: ${props => (props.open ? "0" : "-100vw")};
      background-color: ${props =>
        props.open ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0)"};
      transition: background-color 250ms linear,
        left 0ms linear ${props => (props.open ? "0ms" : "250ms")};
      z-index: 5;

      & > .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 25px;
        width: 250px;
        height: 100%;
        background-color: black;
        transform: ${props => (props.open ? "none" : "translate(-240px, 0px)")};
        transition: transform 250ms linear;

        & > .nav-item {
          cursor: pointer;
          padding: 10px 5px 5px 15px;
          font-family: GothamBook;
          font-size: 15px;
          color: ${textColor};
          height: 50px;
          text-decoration: none;
        }

        & > button {
          z-index: 1;
          position: relative;
          font-size: inherit;
          font-family: GothamMedium;
          color: white;
          margin: 100px 25px 0px;
          padding: 0.5em 1em;
          outline: none;
          border: none;
          background-color: black;
          overflow: hidden;
          transition: color 0.4s ease-in-out;
          border: 1px solid white;
          min-width: 100px;
          min-height: 36px;

          display: flex;
          justify-content: center;
          align-items: center;
        }

        & > button::before {
          content: "";
          z-index: -1;
          position: absolute;
          top: 100%;
          left: 100%;
          min-width: 192px;
          min-height: 50px;
          border-radius: 50%;
          background-color: white;
          transform-origin: center;
          transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
          transition: transform 0.45s ease-in-out;
        }

        & > button:hover {
          cursor: pointer;
          color: #161616;
        }

        & > button:hover::before {
          transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
        }
      }
    `
  };

  return Navbar;
}
