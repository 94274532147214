import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "../Widget2";

const FooterFactory = config => {
  class Footer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        openDialog: false
      };
    }
    render() {
      let { extraStyle = "" } = this.props;
      let { openDialog } = this.state;

      return (
        <Wrapper extraStyle={extraStyle}>
          <Content>
            <Widget.Row css="margin-bottom: 20px;">
              <a
                href="https://www.facebook.com/MapexDrums/"
                target="_balnk"
                className="icon-container"
              >
                <img src={"/images/icon-facebook.png"} className="icon" />
              </a>
              <a
                href="https://www.instagram.com/mapexdrums/"
                target="_blank"
                className="icon-container"
              >
                <img src={"/images/icon-instagram.png"} className="icon" />
              </a>
              <a
                href="https://twitter.com/mapexdrumsusa?lang=zh-tw"
                target="_blank"
                className="icon-container"
              >
                <img src={"/images/icon-twitter.png"} className="icon" />
              </a>
            </Widget.Row>
            <img className="logo" src="/images/khs-logo.png" />
            <Widget.Row
              css={`
                margin-bottom: 20px;
                @media screen and (max-width: 550px) {
                  flex-wrap: wrap;
                }
              `}
            >
              <a
                className="link"
                href="http://www.mapexdrums.com"
                target="_blank"
              >
                MAPEX Drums Official Site
              </a>
              <a
                className="link"
                href="http://mapexdrums.com/international/contact/"
                target="_blank"
              >
                Contact Us
              </a>
              <a
                className="link"
                href="http://mapexdrums.com/international/locator/"
                target="_blank"
              >
                Where To Buy
              </a>
              <a
                className="link"
                href="http://khs-america.com/privacy/"
                target="_blank"
              >
                Privacy Statement
              </a>
            </Widget.Row>
            <div className="text">
              {"© 2019 MAPEX DRUMS, INC.\n All rights reserved."}
            </div>
            <div className="text">
              {"Suggest Browser: Google Chrome 6.0 or above"}
            </div>
          </Content>
        </Wrapper>
      );
    }
  }

  let Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 60px 10px;
    width: 100%;
    min-height: 300px;
    background-color: black;

    ${props => props.extraStyle};
  `;

  let Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .logo {
      margin-bottom: 30px;
      width: 100px;
      height: 89px;
    }

    & .icon-container {
      margin-right: 30px;

      :last-child {
        margin-right: 0px;
      }
    }

    & .icon {
      cursor: pointer;
      width: 35px;
      height: 35px;
    }

    & .link {
      cursor: pointer;
      margin-right: 30px;
      text-decoration: none;
      font-size: 16px;
      color: white;

      :last-child {
        margin-right: 0px;
      }

      @media screen and (max-width: 750px) {
        max-width: 200px;
        font-size: 12px;
      }

      @media screen and (max-width: 565px) {
        max-width: 100%;
        width: 100%;
        font-size: 10px;
        text-align: center;
        margin: 0px 0px 10px 0px;
      }
    }

    & > .text {
      text-align: center;
      font-size: 14px;
      color: #6f6f6f;

      @media screen and (max-width: 340px) {
        white-space: pre-wrap;
      }
    }
  `;

  return Footer;
};

export default FooterFactory;
