import { injectGlobal } from "styled-components";
import GothamBook from "./Fonts/GothamBook.ttf";
import GothamBlack from "./Fonts/GothamBlack.ttf";
import GothamMedium from "./Fonts/GothamMedium.ttf";
import GothamBold from "./Fonts/GothamBold.ttf";
import GothamUltra from "./Fonts/GothamUltra.ttf";
import BankGothic from "./Fonts/BankGothic.ttf";
import BankGothicLHHeavy from "./Fonts/BankGothicLHHeavy.ttf";

injectGlobal`
  @font-face {
     font-family: 'GothamBook';
     src: url(${GothamBook});
  }
`;

injectGlobal`
  @font-face {
    font-family: 'GothamBlack';
    src: url(${GothamBlack});
  }
`;

injectGlobal`
  @font-face {
    font-family: 'GothamMedium';
    src: url(${GothamMedium}), local("微軟正黑體"), local("Microsoft JhengHei");
    unicode-range: U+00-024F;
  }
`;

injectGlobal`
  @font-face {
    font-family: 'GothamBold';
    src: url(${GothamBold});
  }
`;

injectGlobal`
  @font-face {
    font-family: 'GothamUltra';
    src: url(${GothamUltra});
  }
`;

injectGlobal`
  @font-face {
     font-family: 'BankGothic';
     src: url(${BankGothic});
  }
`;

injectGlobal`
  @font-face {
    font-family: 'BankGothicLHHeavy';
    src: url(${BankGothicLHHeavy});
  }
`;
